define("ember-cli-string-helpers/helpers/html-safe", ["exports", "@ember/component/helper", "@ember/template", "ember-cli-string-helpers/-private/create-string-helper"], function (_exports, _helper, _template, _createStringHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.htmlSafe = void 0;
  const htmlSafe = (0, _createStringHelper.default)(_template.htmlSafe);
  _exports.htmlSafe = htmlSafe;

  var _default = (0, _helper.helper)(htmlSafe);

  _exports.default = _default;
});