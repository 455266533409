define("ember-cli-string-helpers/helpers/lowercase", ["exports", "@ember/component/helper", "ember-cli-string-helpers/utils/lowercase", "ember-cli-string-helpers/-private/create-string-helper"], function (_exports, _helper, _lowercase, _createStringHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.lowercase = void 0;
  const lowercase = (0, _createStringHelper.default)(_lowercase.default);
  _exports.lowercase = lowercase;

  var _default = (0, _helper.helper)(lowercase);

  _exports.default = _default;
});