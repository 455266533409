define("ember-cli-string-helpers/helpers/titleize", ["exports", "@ember/component/helper", "ember-cli-string-helpers/utils/titleize", "ember-cli-string-helpers/-private/create-string-helper"], function (_exports, _helper, _titleize, _createStringHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.titleize = void 0;
  const titleize = (0, _createStringHelper.default)(_titleize.default);
  _exports.titleize = titleize;

  var _default = (0, _helper.helper)(titleize);

  _exports.default = _default;
});