define("ember-custom-actions/actions/action", ["exports", "@ember/debug", "@ember/array", "@ember/string", "@ember/array/proxy", "@ember/object/proxy", "@ember/application", "@ember/object/computed", "@ember/utils", "@ember/object", "@ember/object/promise-proxy-mixin", "rsvp", "lodash.merge", "ember-custom-actions/utils/normalize-payload", "ember-custom-actions/utils/url-builder"], function (_exports, _debug, _array, _string, _proxy, _proxy2, _application, _computed, _utils, _object, _promiseProxyMixin, _rsvp, _lodash, _normalizePayload, _urlBuilder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const promiseProxies = {
    array: _proxy.default.extend(_promiseProxyMixin.default),
    object: _proxy2.default.extend(_promiseProxyMixin.default)
  };

  var _default = _object.default.extend({
    id: '',
    model: null,
    instance: false,
    integrated: false,

    init() {
      this._super(...arguments);

      (false && !(this.get('model')) && (0, _debug.assert)('Custom actions require model property to be passed!', this.get('model')));
      (false && !(!(this.get('instance') && !this.get('model.id'))) && (0, _debug.assert)('Custom action model has to be persisted!', !(this.get('instance') && !this.get('model.id'))));
    },

    /**
      @private
      @return {DS.Store}
    */
    store: (0, _computed.readOnly)('model.store'),

    /**
      @public
      @return {Object}
    */
    options: (0, _object.computed)({
      get() {
        return {};
      },

      set(key, value) {
        return value;
      }

    }),

    /**
      @private
      @return {String}
    */
    modelName: (0, _object.computed)('model', function () {
      let {
        constructor
      } = this.get('model');
      return constructor.modelName || constructor.typeKey;
    }).readOnly(),

    /**
      @private
      @return {DS.Adapter}
    */
    adapter: (0, _object.computed)('modelName', 'store', function () {
      return this.get('store').adapterFor(this.get('modelName'));
    }).readOnly(),

    /**
      @private
      @return {DS.Serializer}
    */
    serializer: (0, _object.computed)('modelName', 'store', function () {
      return this.get('store').serializerFor(this.get('modelName'));
    }).readOnly(),

    /**
      @private
      @return {Ember.Object}
    */
    config: (0, _object.computed)('options', 'model', function () {
      let model = this.get('model');
      let appConfig = model ? (0, _application.getOwner)(model).resolveRegistration('config:environment').emberCustomActions || {} : {};
      let mergedConfig = (0, _lodash.default)({}, appConfig, this.get('options'));
      return _object.default.create(mergedConfig);
    }).readOnly(),

    /**
      @public
      @method callAction
      @return {Promise}
    */
    callAction() {
      let promise = this._promise();

      let responseType = (0, _string.camelize)(this.get('config.responseType') || '');
      let promiseProxy = promiseProxies[responseType];
      return promiseProxy ? promiseProxy.create({
        promise
      }) : promise;
    },

    /**
      @private
      @method queryParams
      @return {Object}
    */
    queryParams() {
      let queryParams = this.get('config.queryParams');
      (false && !((0, _utils.typeOf)(queryParams) === 'object') && (0, _debug.assert)('Custom action queryParams option has to be an object', (0, _utils.typeOf)(queryParams) === 'object'));
      return this.get('adapter').sortQueryParams(queryParams);
    },

    /**
      @private
      @method requestMethod
      @return {String}
    */
    requestMethod() {
      let integrated = this.get('integrated') && this.get('adapter').methodForCustomAction;
      let method = this.get('config.method').toUpperCase();
      return integrated ? this._methodForCustomAction(method) : method;
    },

    /**
      @private
      @method requestUrl
      @return {String}
    */
    requestUrl() {
      let integrated = this.get('integrated') && this.get('adapter').urlForCustomAction;
      return integrated ? this._urlForCustomAction() : this._urlFromBuilder();
    },

    /**
      @private
      @method requestHeaders
      @return {String}
    */
    requestHeaders() {
      let integrated = this.get('integrated') && this.get('adapter').headersForCustomAction;
      let configHeaders = this.get('config.headers');
      let headers = integrated ? this._headersForCustomAction(configHeaders) : configHeaders;
      (false && !((0, _utils.typeOf)(headers) === 'object') && (0, _debug.assert)('Custom action headers option has to be an object', (0, _utils.typeOf)(headers) === 'object'));
      return headers;
    },

    /**
      @private
      @method requestData
      @return {Object}
    */
    requestData() {
      let integrated = this.get('integrated') && this.get('adapter').dataForCustomAction;
      let payload = this.get('config.data');
      let data = (integrated ? this._dataForCustomAction(payload) : payload) || {};
      (false && !((0, _utils.typeOf)(data) === 'object') && (0, _debug.assert)('Custom action payload has to be an object', (0, _utils.typeOf)(data) === 'object'));
      return (0, _normalizePayload.default)(data, this.get('config.normalizeOperation'));
    },

    /**
      @private
      @method ajaxOptions
      @return {Object}
    */
    ajaxOptions() {
      return (0, _lodash.default)({}, this.get('config.ajaxOptions'), {
        data: this.requestData(),
        headers: this.requestHeaders()
      });
    },

    // Internals
    _promise() {
      return this.get('adapter').ajax(this.requestUrl(), this.requestMethod(), this.ajaxOptions()).then(this._onSuccess.bind(this), this._onError.bind(this));
    },

    _onSuccess(response) {
      if (this.get('config.pushToStore') && this._validResponse(response)) {
        let store = this.get('store');
        let modelClass = this.get('model.constructor');
        let modelId = this.get('model.id');
        let actionId = this.get('id');
        let documentHash = this.get('serializer').normalizeArrayResponse(store, modelClass, response, modelId, actionId);
        return this.get('store').push(documentHash);
      }

      return response;
    },

    _onError(error) {
      if (this.get('config.pushToStore') && (0, _array.isArray)(error.errors)) {
        let id = this.get('model.id');
        let typeClass = this.get('model').constructor;
        error.serializedErrors = this.get('serializer').extractErrors(this.get('store'), typeClass, error, id);
      }

      return (0, _rsvp.reject)(error);
    },

    _validResponse(object) {
      return (0, _utils.typeOf)(object) === 'object' && Object.keys(object).length > 0;
    },

    _urlFromBuilder() {
      let path = this.get('id');
      let queryParams = this.queryParams();
      let modelName = this.get('modelName');
      let id = this.get('instance') ? this.get('model.id') : null;

      let url = this.get('adapter')._buildURL(modelName, id);

      return (0, _urlBuilder.default)(url, path, queryParams);
    },

    // Adapter integration API
    _urlForCustomAction() {
      let id = this.get('model.id');
      let actionId = this.get('id');
      let queryParams = this.queryParams();
      let modelName = this.get('modelName');
      let adapterOptions = this.get('config.adapterOptions');

      let snapshot = this.get('model')._internalModel.createSnapshot({
        adapterOptions
      });

      return this.get('adapter').urlForCustomAction(modelName, id, snapshot, actionId, queryParams);
    },

    _methodForCustomAction(method) {
      let actionId = this.get('id');
      let modelId = this.get('model.id');
      return this.get('adapter').methodForCustomAction({
        method,
        actionId,
        modelId
      });
    },

    _headersForCustomAction(headers) {
      let actionId = this.get('id');
      let modelId = this.get('model.id');
      return this.get('adapter').headersForCustomAction({
        headers,
        actionId,
        modelId
      });
    },

    _dataForCustomAction(data) {
      let actionId = this.get('id');
      let modelId = this.get('model.id');
      let model = this.get('model');
      return this.get('adapter').dataForCustomAction({
        data,
        actionId,
        modelId,
        model
      });
    }

  });

  _exports.default = _default;
});