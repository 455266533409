define("ember-composable-helpers/helpers/pick", ["exports", "@ember/component/helper", "@ember/object"], function (_exports, _helper, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.pick = pick;
  _exports.default = void 0;

  function pick([path, action]
  /*, hash*/
  ) {
    return function (event) {
      let value = (0, _object.get)(event, path);

      if (!action) {
        return value;
      }

      action(value);
    };
  }

  var _default = (0, _helper.helper)(pick);

  _exports.default = _default;
});