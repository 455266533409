define("ember-composable-helpers/helpers/invoke", ["exports", "@ember/array", "@ember/component/helper", "rsvp"], function (_exports, _array, _helper, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.invoke = invoke;
  _exports.default = void 0;
  const {
    all
  } = _rsvp.default;

  function invoke([methodName, ...args]) {
    let obj = args.pop();

    if ((0, _array.isArray)(obj)) {
      return function () {
        let promises = obj.map(item => {
          var _item$methodName;

          return (_item$methodName = item[methodName]) === null || _item$methodName === void 0 ? void 0 : _item$methodName.call(item, ...args);
        });
        return all(promises);
      };
    }

    return function () {
      var _obj$methodName;

      return (_obj$methodName = obj[methodName]) === null || _obj$methodName === void 0 ? void 0 : _obj$methodName.call(obj, ...args);
    };
  }

  var _default = (0, _helper.helper)(invoke);

  _exports.default = _default;
});