define("ember-bootstrap-changeset-validations/components/bs-form", ["exports", "@ember/debug", "ember-bootstrap/components/bs-form"], function (_exports, _debug, _bsForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class BsFormWithChangesetValidationsSupport extends _bsForm.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, '__ember-bootstrap_subclass', true);
    }

    get hasValidator() {
      return typeof this.model?.validate === 'function';
    }

    async validate(model) {
      let m = model;
      (false && !(m && typeof m.validate === 'function') && (0, _debug.assert)('Model must be a Changeset instance', m && typeof m.validate === 'function'));
      await m.validate();

      if (!model.get('isValid')) {
        throw new Error();
      }
    }

  }

  _exports.default = BsFormWithChangesetValidationsSupport;
});