define("ember-on-modifier/helpers/prevent-default", ["exports", "@ember/application/deprecations", "@ember/component/helper", "@ember/debug"], function (_exports, _deprecations, _helper, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.preventDefault = preventDefault;
  _exports.default = void 0;

  function preventDefault([handler]) {
    (false && !(false) && (0, _deprecations.deprecate)('`(prevent-default)` has been moved to `ember-event-helpers`.', false, {
      id: 'ember-on-modifier.prevent-default',
      until: '1.0.0',
      url: 'https://github.com/buschtoens/ember-event-helpers'
    }));
    (false && !(!handler || typeof handler === 'function') && (0, _debug.assert)(`Expected '${handler}' to be a function, if present.`, !handler || typeof handler === 'function'));
    return function (event) {
      (false && !(event && typeof event.preventDefault === 'function') && (0, _debug.assert)(`Expected '${event}' to be an Event and have a 'preventDefault' method.`, event && typeof event.preventDefault === 'function'));
      event.preventDefault();
      if (handler) handler(event);
    };
  }

  var _default = (0, _helper.helper)(preventDefault);

  _exports.default = _default;
});